import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from "@mui/material";
import { Container } from "@mui/system";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import UserAppBar from "../Components/UserAppBar";
import axios from "axios";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { BASE_URL, stripeDataPoints } from "../constants";
import APIKit from "../services/api";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import lodash from "lodash";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Sparklines,
  SparklinesLine,
  SparklinesReferenceLine,
} from "react-sparklines";
import { time_convert } from "../utils";
import moment from "moment";
import StripeRevTable from "../Components/StripeRevTable";
import AccountActionsPanel from "../Components/Dashboard/AccountActionsPanel";

const dataFiller = (accountProgress) => {
  try {
    const accountsDates = {};
    const allPossibleDates = {};
    let arrangedAccountProgress = {};
    for (let singleProgress of accountProgress) {
      const accountSignatureLong = `${singleProgress.yyyymmdd}-${singleProgress.handle}`;
      arrangedAccountProgress[accountSignatureLong] = singleProgress;
      if (!accountsDates[singleProgress.handle]) {
        accountsDates[singleProgress.handle] = [];
      }
      allPossibleDates[singleProgress.yyyymmdd] = 1;
      accountsDates[singleProgress.handle].push(singleProgress.yyyymmdd);
    }
    let i = 0;
    console.log(
      "Starting total progressess are",
      Object.keys(arrangedAccountProgress).length
    );
    const allDatesArr = Object.keys(allPossibleDates);
    for (let singleDate of allDatesArr) {
      for (let singleAccount in accountsDates) {
        const accountSignatureLong = `${singleDate}-${singleAccount}`;
        const previousDayAccountSignature = `${
          allDatesArr[i - 1]
        }-${singleAccount}`;
        const previousProgress =
          arrangedAccountProgress[previousDayAccountSignature];
        if (
          !arrangedAccountProgress[accountSignatureLong] &&
          previousProgress
        ) {
          console.log(
            "Success missing",
            accountSignatureLong,
            "adding",
            i - 1,
            allDatesArr[i - 1],
            previousDayAccountSignature
          );
          arrangedAccountProgress[accountSignatureLong] = {
            ...previousProgress,
            yyyymmdd: singleDate,
            handle: singleAccount,
          };
        } else {
          console.log(
            "failed missing",
            accountSignatureLong,
            "adding",
            i - 1,
            allDatesArr[i - 1],
            previousDayAccountSignature
          );
        }
      }
      i++;
    }
    console.log(
      "Now total progressess are",
      Object.keys(arrangedAccountProgress).length
    );
    return arrangedAccountProgress;
  } catch (e) {
    console.log("error on dataFiller", e);
    return null;
  }
};

const MetricBox = ({ title, count, change, progress, description }) => {
  return (
    <Tooltip title={description || null} placement="top-end" arrow>
      <Box
        component="span"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2,
          border: "1px dashed grey",
          width: "150px",
          maxWidth: "160px",
          background: "rgb(231, 235, 240)",
          position: "relative",
        }}
      >
        <Typography component="h1" variant="h6" textAlign="center">
          {title}
        </Typography>
        {/* <Divider /> */}
        <Sparklines data={progress} height={100}>
          <SparklinesLine color="blue" />
          {/* <SparklinesReferenceLine type="mean" /> */}
        </Sparklines>
        <Divider />
        <Typography component="h1" variant="h5" textAlign="center">
          {count || 0}
        </Typography>
        {!!description && (
          <InfoIcon sx={{ position: "absolute", top: 2, right: 2 }} />
        )}
        {!!change && (
          <Box
            sx={{
              border: "1px dashed grey",
              background: "#e5e5e5",
              borderRadius: 100,
              padding: "5px",
            }}
          >
            {!!change && change >= 0 && (
              <Typography variant="h5" style={{ color: "green" }}>
                <KeyboardDoubleArrowUpIcon color="success" />
                {change}
              </Typography>
            )}
            {!!change && change < 0 && (
              <Typography>
                <KeyboardDoubleArrowDownIcon
                  color="danger"
                  style={{ color: "red" }}
                />
                {change}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default function MarketingDashboard() {
  const [accounts, setAccounts] = useState([]);
  const [maWorkers, setMaWorkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState({});
  const [metrics, setMetrics] = useState({
    basic: [],
    ma: [],
    meta: {},
  });
  console.log("filter===", filterDetails);
  console.log("workers===", maWorkers);
  const fetchAllAccounts = async () => {
    try {
      const { data } = await APIKit.get(`${BASE_URL}/api/accounts`);
      console.log("ako data ", data);
      if (data && data.accounts) {
        setAccounts(data.accounts.map((s) => s.username));
      }
    } catch (e) {
      console.log("fetch error fetchAllAccounts", e);
    }
  };
  const fetchAllMAWorkers = async () => {
    try {
      const { data } = await APIKit.get(`${BASE_URL}/api/maWorkers`);
      console.log("ako workers ", data);
      if (data && data.users) {
        setMaWorkers(data.users.map((s) => ({ label: s.username, id: s.id })));
      }
    } catch (e) {
      console.log("fetch error fetchAllMAWorkers", e);
    }
  };
  const calculateStripeData = (branchProgress) => {
    const masterData = stripeDataPoints.reduce((acc, cur) => {
      acc[cur] = 0;
      return acc;
    }, {});
    const data = stripeDataPoints.reduce((acc, cur) => {
      acc[cur] = 0;
      return acc;
    }, {});
    const raw = { master: [], other: [] };
    for (let singleProgress of branchProgress) {
      if (singleProgress.handle === "master_account") {
        stripeDataPoints.map((s) => {
          if (singleProgress[s]) {
            raw.master.push(`${singleProgress.yyyymmdd}-${s}`);
          }
          masterData[s] += singleProgress[s] || 0;
        });
      } else {
        stripeDataPoints.map((s) => {
          if (singleProgress[s]) {
            raw.other.push(`${singleProgress.yyyymmdd}-${s}`);
          }
          data[s] += singleProgress[s] || 0;
        });
      }
    }

    console.log("master stripe=====", masterData);
    console.log("others stripe=====", data);
    console.log("hhhh", raw);
    console.log("Diff 1", lodash.difference(raw.master, raw.other));
    console.log("Diff 2", lodash.difference(raw.other, raw.master));
    return masterData;
  };
  const calculateFirstAndLastProgress = (accountProgress) => {
    if (!accountProgress.length) {
      return {
        accountProgress: [],
        firstProgress: null,
        lastProgress: null,
        totalHolders: 0,
      };
    }
    if (filterDetails.username) {
      return {
        accountProgress,
        firstProgress: accountProgress[0],
        lastProgress: accountProgress[accountProgress.length - 1],
        totalHolders: 1,
      };
    } else {
      const progressHolder = {};
      const accounts = {};
      let filteredAccountProgress = {};
      // console.log('Raw account progress', accountProgress.length);
      const triedfilteredAccountProgress = dataFiller(accountProgress);
      console.log('dataFiller Result', triedfilteredAccountProgress)
      if (triedfilteredAccountProgress) {
        filteredAccountProgress = { ...triedfilteredAccountProgress };
      } else {
        for (let singleProgress of accountProgress) {
          const accountSignatureLong = `${singleProgress.yyyymmdd}-${singleProgress.handle}`;
          filteredAccountProgress[accountSignatureLong] = singleProgress;
        }
      }

      filteredAccountProgress = Object.values(filteredAccountProgress);
      // console.log('filtered account progress', filteredAccountProgress.length);
      for (let singleProgress of filteredAccountProgress) {
        const accountSignature = singleProgress.yyyymmdd;
        accounts[singleProgress.handle] = 1;
        if (!progressHolder[accountSignature]) {
          progressHolder[accountSignature] = { ...singleProgress };
        } else {
          progressHolder[accountSignature].followers +=
            singleProgress.followers;
          progressHolder[accountSignature].followersToday +=
            singleProgress.followersToday;
          progressHolder[accountSignature].postCount +=
            singleProgress.postCount;
          progressHolder[accountSignature].postCountToday +=
            singleProgress.postCountToday;
        }
      }
      const newAccountProgress = Object.values(progressHolder);
      return {
        accountProgress: newAccountProgress,
        firstProgress: newAccountProgress[0],
        lastProgress: newAccountProgress[newAccountProgress.length - 1],
        totalHolders: Object.values(accounts).length,
      };
    }
  };

  const calculateFirstAndLastBranchProgress = (rawBranchProgress) => {
    const branchProgress = !filterDetails.username
      ? rawBranchProgress.filter((s) => s.handle !== "master_account")
      : rawBranchProgress;
    if (!branchProgress.length) {
      return {
        branchProgress: [],
        firstBranchProgress: null,
        lastBranchProgress: null,
        totalHolders: 0,
      };
    }
    if (filterDetails.username) {
      return {
        branchProgress,
        firstBranchProgress: branchProgress[0],
        lastBranchProgress: branchProgress[branchProgress.length - 1],
        totalHolders: 1,
      };
    } else {
      const progressHolder = {};
      const accounts = {};
      let filteredbranchProgress = {};
      // console.log('Raw account progress', branchProgress.length);
      for (let singleProgress of branchProgress) {
        const accountSignatureLong = `${singleProgress.yyyymmdd}-${singleProgress.handle}`;
        filteredbranchProgress[accountSignatureLong] = singleProgress;
      }
      filteredbranchProgress = Object.values(filteredbranchProgress);
      // console.log('filtered account progress', filteredbranchProgress.length);
      for (let singleProgress of filteredbranchProgress) {
        const accountSignature = singleProgress.yyyymmdd;
        accounts[singleProgress.handle] = 1;
        if (!progressHolder[accountSignature]) {
          progressHolder[accountSignature] = { ...singleProgress };
        } else {
          progressHolder[accountSignature].opensCount +=
            singleProgress.opensCount;
          progressHolder[accountSignature].opensCountToday +=
            singleProgress.opensCountToday;
          progressHolder[accountSignature].installsCount +=
            singleProgress.installsCount;
          progressHolder[accountSignature].installsCountToday +=
            singleProgress.installsCountToday;
          progressHolder[accountSignature].clicksCount +=
            singleProgress.clicksCount;
          progressHolder[accountSignature].clicksCountToday +=
            singleProgress.clicksCountToday;
          progressHolder[accountSignature].appStoreCount +=
            singleProgress.appStoreCount;
          progressHolder[accountSignature].appStoreCountToday +=
            singleProgress.appStoreCountToday;
          progressHolder[accountSignature].playStoreCount +=
            singleProgress.playStoreCount;
          progressHolder[accountSignature].playStoreCountToday +=
            singleProgress.playStoreCountToday;
        }
      }
      const newbranchProgress = Object.values(progressHolder);
      return {
        branchProgress: newbranchProgress,
        firstBranchProgress: newbranchProgress[0],
        lastBranchProgress: newbranchProgress[newbranchProgress.length - 1],
        totalHolders: Object.values(accounts).length,
      };
    }
  };

  const calculateFirstAndLastMasterProgress = (rawMasterProgress) => {
    const masterProgress = rawMasterProgress.filter(
      (s) => s.handle === "master_account"
    );
    if (!masterProgress.length) {
      return {
        masterProgress: [],
        firstMasterProgress: null,
        lastMasterProgress: null,
        totalHolders: 0,
      };
    }
    if (filterDetails.username) {
      return {
        masterProgress,
        firstMasterProgress: masterProgress[0],
        lastMasterProgress: masterProgress[masterProgress.length - 1],
        totalHolders: 1,
      };
    } else {
      const progressHolder = {};
      const accounts = {};
      let filteredmasterProgress = {};
      // console.log('Raw account progress', masterProgress.length);
      for (let singleProgress of masterProgress) {
        const accountSignatureLong = `${singleProgress.yyyymmdd}-${singleProgress.handle}`;
        filteredmasterProgress[accountSignatureLong] = singleProgress;
      }
      filteredmasterProgress = Object.values(filteredmasterProgress);
      // console.log('filtered account progress', filteredmasterProgress.length);
      for (let singleProgress of filteredmasterProgress) {
        const accountSignature = singleProgress.yyyymmdd;
        accounts[singleProgress.handle] = 1;
        if (!progressHolder[accountSignature]) {
          progressHolder[accountSignature] = { ...singleProgress };
        } else {
          progressHolder[accountSignature].opensCount +=
            singleProgress.opensCount;
          progressHolder[accountSignature].opensCountToday +=
            singleProgress.opensCountToday;
          progressHolder[accountSignature].installsCount +=
            singleProgress.installsCount;
          progressHolder[accountSignature].installsCountToday +=
            singleProgress.installsCountToday;
          progressHolder[accountSignature].clicksCount +=
            singleProgress.clicksCount;
          progressHolder[accountSignature].clicksCountToday +=
            singleProgress.clicksCountToday;
          progressHolder[accountSignature].appStoreCount +=
            singleProgress.appStoreCount;
          progressHolder[accountSignature].appStoreCountToday +=
            singleProgress.appStoreCountToday;
          progressHolder[accountSignature].playStoreCount +=
            singleProgress.playStoreCount;
          progressHolder[accountSignature].playStoreCountToday +=
            singleProgress.playStoreCountToday;
        }
      }
      const newmasterProgress = Object.values(progressHolder);
      return {
        masterProgress: newmasterProgress,
        firstMasterProgress: newmasterProgress[0],
        lastMasterProgress: newmasterProgress[newmasterProgress.length - 1],
        totalHolders: Object.values(accounts).length,
      };
    }
  };
  const fetchDashboardMetrics = async () => {
    setLoading(true);
    try {
      const { data } = await APIKit.post(`${BASE_URL}/api/dashboardMetrics`, {
        startYYYYMMDD: moment().format("YYYYMMDD"),
        endYYYYMMDD: moment().format("YYYYMMDD"),
        ...filterDetails,
      });
      const _metrics = { ...metrics };
      console.log("metrics are", data);
      console.log("metrics String are", JSON.stringify(data));
      if (
        data &&
        ((data.accountProgress && data.accountProgress.length) ||
          (data.branchProgress && data.branchProgress.length))
      ) {
        const stripeData = calculateStripeData(data.branchProgress || []);
        _metrics.stripeData = stripeData;
        const { firstProgress, lastProgress, totalHolders, accountProgress } =
          calculateFirstAndLastProgress(data.accountProgress);

        const { firstBranchProgress, lastBranchProgress, branchProgress } =
          calculateFirstAndLastBranchProgress(data.branchProgress);
        let { firstMasterProgress, lastMasterProgress, masterProgress } =
          calculateFirstAndLastMasterProgress(data.branchProgress);
        if (filterDetails.username) {
          firstMasterProgress = firstBranchProgress;
          lastMasterProgress = lastBranchProgress;
          masterProgress = branchProgress;
        }
        console.log("first branch Progress", firstBranchProgress);
        console.log("last branch Progress", lastBranchProgress);
        console.log("first app Progress", firstMasterProgress);
        console.log("last app Progress", lastMasterProgress);
        _metrics.meta = {
          progressStartDate: firstProgress && firstProgress.yyyymmdd,
          progressEndDate: lastProgress && lastProgress.yyyymmdd,
          branchProgressStart:
            firstBranchProgress && firstBranchProgress.yyyymmdd,
          branchProgressEnd: lastBranchProgress && lastBranchProgress.yyyymmdd,
          hasInfo: true,
        };
        _metrics.basic = [
          {
            title: "Daily SoMe Tracks",
            count: data.accountProgress.length,
            description: "Total Times Some traker tracked any Account",
          },
          {
            title: "Followers",
            count: lastProgress && lastProgress.followers,
            change:
              lastProgress && lastProgress.followers - firstProgress.followers,
            progress: accountProgress.map((s) => s.followers),
          },
          {
            title: "Posts",
            count: lastProgress && lastProgress.postCount,
            change:
              lastProgress && lastProgress.postCount - firstProgress.postCount,
            progress: accountProgress.map((s) => s.postCount),
          },
          {
            title: "Likes",
            count: "N/A",
          },
          {
            title: "Branch Link Clicks",
            count: lastBranchProgress && lastBranchProgress.clicksCount,
            change:
              lastBranchProgress &&
              lastBranchProgress.clicksCount - firstBranchProgress.clicksCount,
            progress: branchProgress.map((s) => s.clicksCount),
          },
          {
            title: "Opens",
            count: lastBranchProgress && lastBranchProgress.opensCount,
            change:
              lastBranchProgress &&
              lastBranchProgress.opensCount - firstBranchProgress.opensCount,
            progress: branchProgress.map((s) => s.opensCount),
          },
          {
            title: "Installs Branch",
            count: lastBranchProgress && lastBranchProgress.installsCount,
            change:
              lastBranchProgress &&
              lastBranchProgress.installsCount -
                firstBranchProgress.installsCount,
            progress: branchProgress.map((s) => s.installsCount),
          },
          {
            title: "Play store",
            count: lastMasterProgress && lastMasterProgress.playStoreCount,
            change:
              lastMasterProgress &&
              lastMasterProgress.playStoreCount -
                firstMasterProgress.playStoreCount,
            progress: masterProgress.map((s) => s.playStoreCount),
          },
          {
            title: "App store",
            count: lastMasterProgress && lastMasterProgress.appStoreCount,
            change:
              lastMasterProgress &&
              lastMasterProgress.appStoreCount -
                firstMasterProgress.appStoreCount,
            progress: masterProgress.map((s) => s.appStoreCount),
          },
        ];
        if (!filterDetails.username) {
          _metrics.basic.unshift({
            title: "Aggregated Tracks",
            count: accountProgress.length,
            description: "SomeTracker rows grouped and aggregated by day",
          });
          _metrics.basic.unshift({
            title: "Total Accounts",
            count: totalHolders,
          });
        }
      } else {
        _metrics.basic = [];
        _metrics.meta = {};
      }
      if (data && data.submissions && data.submissions.length) {
        const firstMa = data.submissions[0];
        const lastMa = data.submissions[data.submissions.length - 1];
        _metrics.meta = {
          ..._metrics.meta,
          maStartDate: firstMa.yyyymmdd,
          maEndDate: lastMa.yyyymmdd,
          hasMa: true,
        };
        let timeSpentFormatted = data.submissions.reduce((accu, curr) => {
          if (curr.minutesSpent) {
            accu = accu + curr.minutesSpent;
          }
          return accu;
        }, 0);
        timeSpentFormatted =
          time_convert(timeSpentFormatted) || `${timeSpentFormatted} mins`;
        _metrics.ma = [
          {
            title: "Submissions",
            count: data.submissions.length,
          },
          {
            title: "Ads Started",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.newAdUrl && curr.newAdUrl.length) {
                accu = accu + 1;
              }
              return accu;
            }, 0),
          },
          {
            title: "Ads Completed",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.adMovedToDoneUrl && curr.adMovedToDoneUrl.length) {
                accu = accu + 1;
              }
              return accu;
            }, 0),
          },
          {
            title: "Amount Spent",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.amountSpent) {
                accu = accu + curr.amountSpent;
              }
              return accu;
            }, 0),
          },
          {
            title: "Ads Engagements",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.engagementsPerClick) {
                accu = accu + curr.engagementsPerClick;
              }
              return accu;
            }, 0),
          },
          {
            title: "Follows Completed",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.accountsFollowed) {
                accu = accu + curr.accountsFollowed;
              }
              return accu;
            }, 0),
          },
          {
            title: "Messages Sent",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.messagesSent) {
                accu = accu + curr.messagesSent;
              }
              return accu;
            }, 0),
          },
          {
            title: "Messages Received",
            count: data.submissions.reduce((accu, curr) => {
              if (curr.messagesReceived) {
                accu = accu + curr.messagesReceived;
              }
              return accu;
            }, 0),
          },
          {
            title: "Account Credit Added",
            count: "N/A",
          },
          {
            title: "Time Spent",
            count: timeSpentFormatted,
          },
        ];
      } else {
        _metrics.ma = [];
        _metrics.meta = {
          ..._metrics.meta,
          hasMa: false,
        };
      }
      console.log("setting metrics", _metrics);
      setMetrics(_metrics);
    } catch (e) {
      console.log("fetch error fetchDashboardMetrics", e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchDashboardMetrics();
    fetchAllAccounts();
    fetchAllMAWorkers();
  }, []);
  useEffect(() => {
    fetchDashboardMetrics();
  }, [filterDetails]);
  const basicMetrics = metrics.basic;
  const maMetrics = metrics.ma;
  const hasInfo = metrics.meta && metrics.meta.hasInfo;
  const hasMa = metrics.meta && metrics.meta.hasMa;
  // const basicMetrics = useMemo(() => {
  //   if (!metrics.basic) {
  //     return [];
  //   }
  //   return Object.keys(metrics.basic).reduce((acc, curr) => {
  //     acc.push({
  //       title: curr,
  //       count: metrics.basic[curr],
  //     });
  //     return acc;
  //   }, [])
  // }, [metrics])
  // const maMetrics = useMemo(() => {
  //   if (!metrics.ma) {
  //     return [];
  //   }
  //   return Object.keys(metrics.ma).reduce((acc, curr) => {
  //     acc.push({
  //       title: curr,
  //       count: metrics.ma[curr],
  //     });
  //     return acc;
  //   }, [])
  // }, [metrics])
  // console.log('metrics', basicMetrics)
  return (
    <div>
      <UserAppBar />
      <Container maxWidth={false}>
        <Box
          style={{ paddingTop: 20 }}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            p: 1,
            m: 1,
            gap: 2,
            justifyContent: "space-around",
          }}
        >
          <div>
          <Autocomplete
            disablePortal
            onChange={(s, val) => {
              console.log("changed", s.target.value, val);
              setFilterDetails((prev) => {
                return {
                  ...prev,
                  platform: "instagram",
                  username: val,
                };
              });
            }}
            id="combo-box-demo"
            options={accounts}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Username" />}
          />
          </div>
          <Autocomplete
            disablePortal
            onChange={(s, val) => {
              setFilterDetails((prev) => {
                return {
                  ...prev,
                  platform: "instagram",
                  // username: val,
                  worker: val && val.id,
                };
              });
            }}
            id="combo-box-demo"
            options={maWorkers}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="MA Worker" />
            )}
          />
          <MobileDatePicker
            closeOnSelect
            onAccept={(val) => {
              setFilterDetails((prev) => {
                return {
                  ...prev,
                  platform: "instagram",
                  // username: val,
                  startYYYYMMDD: val.format("YYYYMMDD"),
                  startDate: val.toISOString(),
                };
              });
            }}
            label="Start date"
            value={filterDetails.startDate || new Date()}
            inputFormat="MM/DD/YYYY"
            onChange={(val) => {
              // setFilterDetails(prev => {
              //   return {
              //     ...prev,
              //     platform: 'instagram',
              //     // username: val,
              //     startDate: val.toISOString(),
              //   }
              // })
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <MobileDatePicker
            label="End date"
            inputFormat="MM/DD/YYYY"
            value={filterDetails.endDate || new Date()}
            // onChange={handleChange}
            onChange={(val) => {
              setFilterDetails((prev) => {
                return {
                  ...prev,
                  platform: "instagram",
                  // username: val,
                  endYYYYMMDD: val.format("YYYYMMDD"),
                  endDate: val.toISOString(),
                };
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        {accounts.length && <AccountActionsPanel filterDetails={filterDetails} accounts={accounts}/>}
        <Paper elevation={3}>
          <Box sx={{ p: 1 }}>
            <Typography component="h1" variant="h5" textAlign="left">
              Info{" "}
              {metrics.meta && metrics.meta.progressStartDate
                ? `(${metrics.meta.progressStartDate} to ${metrics.meta.progressEndDate})`
                : ""}
            </Typography>
          </Box>
          <Alert severity="warning">
            Showing Data for username: {filterDetails.username || "All"}
          </Alert>
          <Box
            style={{ paddingTop: 20 }}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              p: 1,
              m: 1,
              gap: 2,
              justifyContent: "space-around",
            }}
          >
            {basicMetrics.map((s, i) => (
              <MetricBox key={i} {...s} />
            ))}
            {!hasInfo && (
              <Alert severity="warning">
                No Data on this range or username
              </Alert>
            )}
          </Box>
        </Paper>
        <Paper elevation={3}>
          <Box sx={{ p: 1 }}>
            <Typography component="h1" variant="h5" textAlign="left">
              Manual Action{" "}
              {metrics.meta && metrics.meta.maStartDate
                ? `(${metrics.meta.maStartDate} to ${metrics.meta.maEndDate})`
                : ""}
            </Typography>
          </Box>
          <Alert severity="warning">
            Showing Data for username: {filterDetails.username || "All"},
            Worker:{" "}
            {(maWorkers &&
              maWorkers.find((s) => s.id === filterDetails.worker)?.label) ||
              "All"}
          </Alert>
          <Box
            style={{ paddingTop: 20 }}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              p: 10,
              m: 1,
              gap: 2,
              justifyContent: "space-around",
            }}
          >
            {maMetrics.map((s, i) => (
              <MetricBox key={i} title={s.title} count={s.count} />
            ))}
            {!hasMa && (
              <Alert severity="warning">
                No Data on this range or username or maWorker
              </Alert>
            )}
          </Box>
        </Paper>
        <Paper elevation={3}>
          <StripeRevTable data={metrics.stripeData || {}} />
        </Paper>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
